<template>
  <section class="consultantCalculations box-height">
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <p class="title"><i></i> 测算条件</p>
      <el-form
        :model="FormData"
        ref="filter"
        style="
          background-color: #fff;
          padding-top: 15px;
          margin: 0 auto;
          width: 50%;
        "
        label-width="150px"
      >
        <el-form-item prop="yearValue" label="测算年份：" style="margin-bottom: 20px;">
          <el-radio-group
            v-model="FormData.yearValue"
            style="width: 380px; height: 35px"
          >
            <el-radio
              :label="item"
              border
              v-for="(item, index) in yearList"
              :key="index"
              style="width: 120px; z-index: 12;overflow: hidden;"
            >
              {{ item }}
              <div
                v-if="index !== 2"
                id="triangle-topright1"
                class="bodTop2"
                style="display: block; z-index: 99"
              >
                <span v-if="index == 0">今年</span>
                <span v-if="index == 1">去年</span>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="period" label="开票时间：">
          <el-select
            v-model="FormData.period"
            placeholder="请选择开票周期"
            popper-class="bonusCalculationSscSelect"
            style="width: 380px; height: 35px"
          >
            <el-option label="第一季度" value="FISRT_QUARTER"> </el-option>
            <el-option label="前两季度" value="FIRST_TWO_QUARTER"> </el-option>
            <el-option label="前三季度" value="FIRST_THREE_QUARTER">
            </el-option>
            <el-option label="全年" value="WHOLE_YEAR"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="accountEnd" label="回款时间：">
          <el-date-picker
            v-model="FormData.accountStart"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="回款时间自1月1号"
            disabled
            style="width: 170px; height: 35px"
          >
          </el-date-picker>
          <span style="width: 20px; height: 35px"> - </span>
          <el-date-picker
            v-model="FormData.accountEnd"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择回款结束时间"
            :picker-options="accountDateOpt"
            style="width: 197px; height: 35px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="userId" label="测算顾问：">
          <el-cascader
            class="searchCascader"
            style="width: 380px; height: 35px"
            filterable
            clearable
            :options="options2"
            collapse-tags
            :show-all-levels="false"
            v-model="userId"
            :props="props"
            placeholder="请选择小类"
            ref="dataRef"
            @change="getSearch"
            :filter-method="filterFun"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="setBonusReportUserCalculate"
            style="width: 380px"
            >开始测算</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <p class="title"><i></i> 测算结果</p>
      <el-table
        :header-cell-style="{
          'background-color': '#f5f7fa',
        }"
        border
        :data="ListData"
        class="tabBorder custor"
        :span-method="objectSpanMethod"
        v-horizontal-scroll
        style="margin-top: 0"
      >
        <el-table-column prop="userName" min-width="180" class-name="canEdit">
          <template slot="header">
            <span class="header-label">顾问</span>
          </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <p :title="scope.row.userName">
                {{ scope.row.userName }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="workId" min-width="80" label="工号">
          <template slot-scope="scope">
            <span
              v-if="!scope.row.workId && (!scope.row.calculateModelName || '寻访员' == scope.row.calculateModelName)"
              style="
                position: absolute;
                z-index: 2;
                color: #f56c6c;
                background: #ffeff0;
                padding: 2px 10px;
                border: 1px solid #fee2e1;
                display: block;
                width: 236px;
                margin-top: -15px;
                border-radius: 2px;
              "
            >
              没有设置奖金计算模型，请联系管理员</span
            >
            <span v-else>{{ scope.row.workId }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="140" class-name="canEdit">
          <template slot="header">
            <span class="header-label">预计发放总奖金 </span>
            <el-tooltip
              class="item"
              effect="dark"
              content="指职级内待发奖金之和 - 累计已发奖金 - 往年差额"
              placement="bottom"
            >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <span style="margin-right: 10px">{{ scope.row.totalBonus }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template slot="header">
            <span class="header-label" style="font-size: 12px"
              >累计已发奖金
            </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                指测算年度内已发放和待发放的奖金总额。<br />
                待发放的奖金已被锁定额度。</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedPaidBonus !== null"
              @click="showPerformanceDram('accruedPaidBonus', scope.row)"
            >
              {{ scope.row.accruedPaidBonus }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column prop="difference" min-width="180" class-name="canEdit">
          <template slot="header">
            <span class="header-label">去年差额</span>
          </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <p :title="scope.row.difference">
                {{ scope.row.difference }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" class-name="canEdit">
          <template slot="header">
            <span class="header-label">职级 </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{
                scope.row.calculateModelName
                  ? scope.row.calculateModelName
                  : "-"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span class="header-label">开票周期 </span>
            <el-tooltip
              class="item"
              effect="dark"
              content="指对应职级的开票周期。根据查询的“开票时间”自动拆分。"
              placement="bottom"
            >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.invoiceStartDate">
              {{
                scope.row.invoiceStartDate ? scope.row.invoiceStartDate : "-"
              }}至{{
                scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-"
              }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="开票业绩">
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.invoiceMoney"
              @click="showPerformanceDram('Invoicing', scope.row)"
            >
              {{ scope.row.invoiceMoney }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="回款业绩">
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accountMoney"
              @click="showPerformanceDram('Payback', scope.row)"
            >
              {{ scope.row.accountMoney }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column min-width="120">
          <template slot="header">
            <span class="header-label">累计总奖金 </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                计算公式：累计总奖金=回款业绩 * 奖金比例 - 累计固定工资<br />计算规则：按开票时间找对应的奖金计算规则。按回款业绩计算奖金。</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedBonus !== null"
              @click="showPerformanceDram('accruedBonus', scope.row)"
            >
              {{ scope.row.accruedBonus }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template slot="header">
            <span class="header-label" style="font-size: 12px"
              >累计固定工资
            </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                特别说明：<br />
                寻访员奖金与固定工资无关，默认按0处理</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedSalary !== null"
              @click="showPerformanceDram('accruedSalary', scope.row)"
            >
              {{ scope.row.accruedSalary }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleDrawerClose"
    >
      <div slot="title" class="drawerTitle">
        {{ drawerName }}
      </div>
      <el-row v-if="drawerVisible">
        <el-col
          style="
            padding: 10px 15px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-button
            v-if="drawType !== 'accruedBonus'"
            size="mini"
            @click="downInfoExp"
            :loading="btnLoading"
            >导出</el-button
          >
          <span v-if="drawType == 'accruedSalary'"
            >累计固定工资总额：
            <span style="color: orange; font-weight: 600"
              >{{ totalSalary }}
            </span>
            元</span
          >
          <el-pagination
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="page.size"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            style="padding-right: 0px"
          ></el-pagination>
        </el-col>
        <el-col>
          <el-table
            v-if="drawType == 'Invoicing' || drawType == 'Payback'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              v-if="drawType == 'Payback'"
              label="回款时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="drawType == 'Payback'"
              label="开票时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.actualMakeInvoiceDate
                    ? scope.row.actualMakeInvoiceDate.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column v-else label="时间" width="150" prop="">
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="总业绩"></el-table-column>
            <el-table-column
              prop="achievement"
              width="150"
              label="实际业绩"
            ></el-table-column>
            <el-table-column
              label="业绩创造者"
              prop="nickName"
              width="150"
            ></el-table-column>
            <el-table-column
              label="业绩类型"
              width="150"
              prop="roleName"
            ></el-table-column>
            <el-table-column
              label="分配比例（个人）"
              width="160"
              prop="allocatePercentStr"
            >
              <template slot-scope="scope">
                {{
                  scope.row.allocatePercentStr
                    ? scope.row.allocatePercentStr
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="项目名称" show-overflow-tooltip>
              <template #default="scope">
                <router-link
                  class="tabHref"
                  target="_blank"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.projectName }}
                </router-link>
              </template></el-table-column
            >
            <el-table-column label="候选人" width="120">
              <template #default="scope">
                <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                  scope.row.resumeName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="industryName" label="行业">
              <template #default="scope">
                {{ scope.row.industryName ? scope.row.industryName : "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="userName"
              label="顾问"
              min-width="180"
            ></el-table-column>
            <el-table-column label="开票周期" min-width="180">
              <template slot-scope="scope">
                <p v-if="scope.row.invoiceStartDate">
                  {{
                    scope.row.invoiceStartDate
                      ? scope.row.invoiceStartDate
                      : "-"
                  }}至{{
                    scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-"
                  }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="calculateModelName"
              label="职级"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="accountMoney"
              label="回款业绩"
            ></el-table-column>
            <el-table-column label="*" width="40">
              <template slot-scope="scope">
                <p>*</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="baseRatio"
              label="比例"
              width="80"
            ></el-table-column>
            <el-table-column label="-" width="40">
              <template slot-scope="scope">
                <p>-</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedSalary" min-width="180">
              <template slot="header">
                <span class="header-label">累计固定工资 </span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    特别说明：<br />
                    寻访员奖金与固定工资无关，默认按0处理</template
                  >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="color: #898989; font-size: 14px; font-weight: 400"
                  ></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <p v-if="scope.row.accruedSalary">
                  {{ scope.row.accruedSalary }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column label="=" width="40">
              <template slot-scope="scope">
                <p>=</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedBonus" width="180">
              <template slot="header">
                <span class="header-label" style="color: #e60012"
                  >累计应发总奖金
                </span>
              </template>
              <template slot-scope="scope">
                <p style="color: #e60012">
                  {{ scope.row.accruedBonus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedPaidBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="planPayDate"
              label="计划发放时间"
            ></el-table-column>
            <el-table-column
              prop="payDate"
              label="实际发放时间"
            ></el-table-column>
            <el-table-column
              prop="bonusTableName"
              label="奖金表名称"
            ></el-table-column>
            <el-table-column
              prop="workId"
              label="工号"
            ></el-table-column>
            <el-table-column
              prop="yearValueStr"
              label="奖金所属年份"
            ></el-table-column>
            <el-table-column
              prop="paidBonus"
              label="发放金额（元）"
            ></el-table-column>
            <el-table-column prop="payStatus" label="发放状态">
              <template slot-scope="scope">
                <p
                  :style="
                    scope.row.payStatus == '待发放' ? 'color: #526ecc' : ''
                  "
                >
                  {{ scope.row.payStatus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedSalary'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column prop="monthValue" label="月份"></el-table-column>
            <el-table-column prop="workTime" label="在职时长"></el-table-column>
            <el-table-column prop="salary" label="固定工资金额">
              <template slot-scope="scope">
                <span style="color: orange">
                  {{ scope.row.salary }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerResumeVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleDrawerClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerResumeVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  permissionTree, //顾问
  bonusAchievementDetailAccount, //回款业绩详情
  bonusAchievementDetailExportAccount, //导出回款业绩
  bonusAchievementDetailInvoice, //开票业绩详情
  bonusAchievementDetailExportInvoice, //导出开票业绩
  bonusRecordItemAccruedBonusList, //累计已发奖金列表
  bonusRecordItemExportAccruedBonus, //导出累计已发奖金列表
  bonusSalaryRecordList, //工资记录列表
  bonusSalaryRecordExport, //导出工资记录列表
  bonusReportUserCalculate, //顾问测算
  bonusRecordItemAccruedBonusInfo, //累计总奖金详情
} from "../../api/api";
import { ApiBaseUrl } from "@/api/http";
import resumeDetails from "../../components/resumeDetail";
export default {
  components: {
    resumeDetails,
  },
  data() {
    return {
      yearList: [
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
      ],
      FormData: {
        userId: null,
        accountEnd: null,
        yearValue: new Date().getFullYear(),
        period: null,
      },
      ListData: [],
      InfoData: [],
      baseUrl: ApiBaseUrl,
      drawerVisible: false,
      btnLoading: false,
      page: {
        current: 1,
        size: 50,
        total: 0,
      },
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      drawerResumeVisible: false,
      drawerName: "",
      drawType: "",
      options2: [],
      userId: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        // multiple: true,
      },
      accountDateOpt: {
        disabledDate: this.accountDisabled,
      },
    };
  },
  created() {
    this.permissionTree(); //顾问
  },
  methods: {
    //禁用时间
    accountDisabled(time) {
      return time.getTime() > Date.now();
    },
    // 小类
    permissionTree() {
      permissionTree({
        type: "顾问",
        showLeaveStatus: true,
        showDisableUser: true,
      }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      this.FormData.userId = this.userId[this.userId.length - 1];
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    setBonusReportUserCalculate() {
      this.ListData = [];
      if (this.FormData.yearValue == null) {
        this.$message.error("请选择测算年份");
        return;
      }
      if (this.FormData.period == null) {
        this.$message.error("请选择开票周期");
        return;
      }
      if (this.FormData.accountEnd == null) {
        this.$message.error("请选择回款时间");
        return;
      }
      if (this.FormData.userId == null) {
        this.$message.error("请选择测算顾问");
        return;
      }
      bonusReportUserCalculate(this.FormData).then((res) => {
        if (res.success) {
          this.ListData = res.result;
          let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if (this.ListData[i].id === this.ListData[i - 1].id) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
            }
          }
        }
      });
    },
    //显示业绩弹窗
    showPerformanceDram(type, data) {
      this.drawType = type;
      this.DrawData = data;
      this.getInfoList();
      this.drawerVisible = true;
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerResumeVisible = true;
      this.resumeId = id;
    },
    handleDrawerClose() {
      this.drawerVisible = false;
      this.drawerResumeVisible = false;
      this.page = { current: 1, size: 50, total: 0 };
    },
    getInfoList() {
      let req = null;
      if (this.drawType == "Invoicing") {
        this.drawerName =
          "开票业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "Payback") {
        this.drawerName =
          "回款业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "accruedBonus") {
        this.drawerName = "累计应发总奖金";
        bonusRecordItemAccruedBonusInfo({ itemId: this.DrawData.itemId }).then(
          (res) => {
            if (res.success) {
              this.InfoData = [res.result];
            }
          }
        );
      } else if (this.drawType == "accruedPaidBonus") {
        this.drawerName =
          "累计已发奖金--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusRecordItemAccruedBonusList({ id: this.DrawData.id }).then(
          (res) => {
            if (res.success) {
              this.InfoData = res.result;
            }
          }
        );
      } else if (this.drawType == "accruedSalary") {
        this.drawerName =
          "累计固定工资--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        this.getBonusSalaryRecordList();
      }
    },
    //获取
    getBonusSalaryRecordList() {
      bonusSalaryRecordList({ itemId: this.DrawData.itemId }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.salaryRecords;
          this.totalSalary = res.result.totalSalary;
        }
      });
    },
    //导出
    downInfoExp() {
      this.btnLoading = true;
      let req = null;
      if (this.drawType == "Invoicing") {
        req = bonusAchievementDetailExportInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "Payback") {
        req = bonusAchievementDetailExportAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "accruedPaidBonus") {
        req = bonusRecordItemExportAccruedBonus({
          id: this.DrawData.id,
        });
      } else if (this.drawType == "accruedSalary") {
        req = bonusSalaryRecordExport({ itemId: this.DrawData.itemId });
      }
      req.then((res) => {
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
        this.btnLoading = false;
      });
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getInfoList();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.getInfoList();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 4) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.consultantCalculations {
  padding: 0 15px;
  margin: 0 15px;
  background: #fff;
  height: calc(100vh - 66px);
  .body {
    // padding:0 15px;
  }

  .content {
    border: 1px #e5e5e5 solid;
    &:hover {
      -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
      box-shadow: 0 3px 8px 1px #e0e0e0;
    }
  }
  .title {
    font-weight: 600;
    margin: 10px 10px 10px 0;
    // border-left: 10px #e60012 solid;
    padding-left: 5px;
    display: flex;
    align-items: center;
    i {
      width: 3px;
      height: 14px;
      display: block;
      background: #e60012;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
  .tag {
    height: 14px;
    line-height: 14px;
  }
  #triangle-topright1 {
    display: none;
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    position: absolute;
    cursor: pointer;
    border-top: 30px solid #c0c4cd;
    border-left: 30px solid transparent;
    padding-top: 3px;
    // padding-left: 2px;
    span {
      color: #fff;
      right: 23px;
      position: relative;
      top: -27px;
      font-size: 12px;
      line-height: 1;
      width: 30px;
      display: block;
      transform: rotate(45deg) scale(0.83);
    }
  }
  .bodTop2 {
    border-top: 30px solid #c0c4cc !important;
  }
}
</style>

<style lang="scss">
.consultantCalculations {
  .el-divider--horizontal {
    height: 8px;
    margin: 20px 0;
  }
  .el-divider {
    background-color: #f1f1f1;
  }
  .el-radio {
    margin-right: 0 !important;
  }
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 10px;
  }
}
</style>
